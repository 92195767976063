<template>
  <base-modal :modal.sync="value" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div></div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">{{ `${form.id ? "Atualizar" : "Cadastrar"} material` }}</h2>
        </div>
        <div class="modal__form--group">
          <r-select label="Categoria" v-model="form.category_id" :items="categories" class="col-12" labelName="title" placeholder="Selecionar categoria" />

          <r-select label="Material" v-model="form.item_id" :readonly="!form.category_id" :items="form.category_id ? items : []" class="col-12" labelName="name" placeholder="Selecionar material" />

          <r-select label="Unidade de medida" v-model="form.unity_id" :items.sync="unities" placeholder="Selecionar unidade de medida" class="col-12" labelName="unity" />

          <r-select label="Estado" v-model="form.province_id" :items.sync="states" labelName="name" placeholder="Selecionar estado" class="col-12" />

          <r-select label="Cidade" v-model="form.city_id" :items.sync="cities" :readonly="!form.province_id" labelName="name" class="col-12" :placeholder="!form.province_id ? 'Selecione um estado' : 'Selecionar cidade'" />

          <div class="dropdown_form d-flex flex-wrap col-12 have-destination">
            <label class="full-width">Certificado de destinação</label>
            <div class="radio_form"></div>
            <div class="form__radio--group">
              <input class="form__radio collect__method--js" type="radio" id="have_destination_yes" name="your-method-collect" :value="1" v-model="form.have_destination" />
              <label class="form__radio--label" for="have_destination_yes">Com certificado</label>
            </div>
            <div class="form__radio--group">
              <input class="form__radio collect__method--js" type="radio" id="have_destination_no" name="your-method-collect" :value="0" v-model="form.have_destination" />
              <label class="form__radio--label" for="have_destination_no">Sem certificado</label>
            </div>
          </div>

          <r-input label="Licença" v-model="form.destination_certification" type="file" v-if="form.have_destination" />

          <div class="col-12 px-md" v-if="form.id && typeof form.destination_certification == 'string'">
            <img :src="$help.file(form.destination_certification)" width="100%" />
          </div>

          <div class="col-12" v-if="form.have_destination">
            <r-input v-model="form.destination_certification_expiration" placeholder="DD/MM/AAAA" type="date" label="Data de expiração do certificado" />
          </div>

          <div class="dropdown_form col-12">
            <label for="observations">Observações</label>
            <textarea id="observations" class="input_text" rows="6" v-model="form.destination_certification_observation"></textarea>
          </div>

          <div class="footer_modal">
            <button :disabled="saving" type="button" @click.prevent="onSave()" class="button_outline">
              {{ saving ? "Salvando..." : form.id ? "Atualizar" : "Cadastrar" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import swal from 'sweetalert'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

const defaultForm = {
  id: null,
  category_id: null,
  item_id: '',
  unity_id: '',
  province_id: null,
  city_id: null,
  have_destination: 0,
  destination_certification: '',
  destination_certification_expiration: new Date(),
  destination_certification_observation: ''
}

export default {
  props: {
    value: Boolean,
    editing: Object
  },
  data: () => ({
    form: { ...defaultForm },
    unities: [],
    items: [],
    categories: [],
    listEnabledStates: [],
    listEnabledCities: [],
    states: [],
    cities: [],
    saving: false
  }),
  computed: {
    ...mapGetters('province', ['provinces']),
    valid () {
      const form = this.form

      if (!form.item_id || !form.city_id || !form.unity_id) {
        return false
      }

      return true
    }
  },
  methods: {
    ...mapActions('material', { save: 'save', fetchMaterials: 'fetch' }),
    async onSave () {
      if (!this.valid) return this.alert('validation')
      this.saving = true
      this.form.enabled = this.form.status === 'Ativo' ? 1 : 0
      delete this.form.status
      const response = await this.$http.post('collectors/materials', this.form)
      this.alert(response.status ? 'success' : 'error', response.message)
      if (response.status) {
        this.$emit('change-modal', false)
      }
      this.saving = false
    },
    alert (type = 'success', text = 'Verifique todos os campos.') {
      swal({
        title: type === 'success' ? 'Sucesso!' : 'Ooops!',
        icon: type === 'success' ? 'success' : 'error',
        text,
        button: type === 'success' ? null : 'Ok',
        timer: type === 'success' ? 1250 : 3000
      })
    },
    async getItems () {
      const url = 'items/category/' + this.form.category_id
      const { data } = await this.$http.get(url)
      this.items = data.data
    },
    async getUnities () {
      const url = 'unities'
      const { data } = await this.$http.get(url)
      this.unities = data.data
    },
    async getCategories () {
      const url = 'categories'
      const { data } = await this.$http.get(url)
      this.categories = data.data
    },
    async enabledCities () {
      const response = await this.$http.get('/cities/enabled')
      const cities = []
      const states = []
      response.data.forEach((value, index) => {
        states.push(value.province_id)
      })
      response.data.forEach((value, index) => {
        cities.push(value.id)
      })
      this.listEnabledCities = _.uniq(cities)
      this.listEnabledStates = _.uniq(states)

      await this.getStates()
    },
    async getStates () {
      const response = await this.$http.get('provinces')

      this.states = _.filter(response.data, state => {
        if (_.includes(this.listEnabledStates, state.id)) {
          return state
        }
      })
    },
    async getCities (state_id) {
      const response = await this.$http.get('/cities?province_id=' + state_id)

      this.cities = _.filter(response.data, city => {
        if (_.includes(this.listEnabledCities, city.id)) {
          return city
        }
      })
    }
  },
  async created () {
    await this.enabledCities()
    await this.getCategories()
    await this.getUnities()
  },
  components: {
    baseModal: () => import('@/components/modal'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },
  watch: {
    'form.item_id' () {
      this.form.recyclable = []
    },
    async 'form.province_id' (val) {
      await this.getCities(val)
    },
    async 'form.category_id' (val) {
      this.items = []
      if (!val) return
      const url = `items?page=1&category_id=${val}`
      const { data } = await this.$http.get(url)
      this.items = data.data
    },
    value (val) {
      if (!val) this.form = { ...defaultForm }
    },
    editing (val) {
      if (!Object.keys(val).length) return this.$emit('input', false)
      const { recyclable, ...args } = val
      Object.assign(this.form, defaultForm, args)
      setTimeout(() => (this.form.recyclable = recyclable), 1)
    }
  }
}
</script>

<style scoped>
.container_upload {
  background-color: #f0f0f0;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  font-size: 40pt;
  text-align: center;
  padding-top: 3%;
  margin-top: 10px;
}
</style>
